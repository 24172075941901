import { createGlobalStyle, css } from "styled-components";

/**
 * Colors
 */

export const colors = {
  superiorWhite: "#ffffff",
  inferiorBlack: "#222222",
  sherpaBlue: "#004a4c",
  orient: "#00527d",
  yellow: "#f9eeb3",
  anzac: "#df9847",
  bleachWhite: "#fdebd4",
  roseOfSharon: "#bd4e01",
  carrotOrange: "#ee9927",
  moonMist: "#ddddcc",
  seaNymph: "#70a59a",
};

/**
 * Typography
 */

export const targetBaseFontSizePx = 18;
const targetBaseLineHeightPx = 28;

const defaultBaseFontSizePx = 16;
export const baseFontSizeRem = targetBaseFontSizePx / defaultBaseFontSizePx;
export const baseLineHeightRem = targetBaseLineHeightPx / targetBaseFontSizePx;

export const fontStacks = {
  nunito: "Nunito, sans-serif",
};

/**
 * Grid spacing
 */

export const spaceRem = baseLineHeightRem;

/**
 * Global styles
 */

export const GlobalStyle = createGlobalStyle`${css`
  /**
   * Use a better box model
   */

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /**
   * Reset paddings and margins
   */

  * {
    margin: 0;
    padding: 0;
  }

  /**
   * Set base text style
   */

  :root {
    font-family: ${fontStacks.nunito};
    font-weight: 400;
    font-style: normal;
    font-size: ${baseFontSizeRem}rem;
    color: ${colors.inferiorBlack};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /**
   * Set base line height
   */

  body {
    line-height: ${baseLineHeightRem}rem;
  }

  /**
   * Set body background
   */

  body {
    background-color: ${colors.yellow};
  }

  /**
   * Set heading text style
   */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    line-height: 1.5;
  }

  /**
   * Hyphenate long words 
   */

  * {
    hyphens: auto;
  }

  /**
   * Set measure
   */

  * {
    max-width: 57ch;
  }

  html,
  body,
  div,
  header,
  nav,
  main,
  footer,
  section,
  iframe,
  svg,
  img,
  input,
  canvas,
  video,
  button {
    max-width: none;
  }

  /**
   * Reset list styles
   */

  ul,
  ol,
  dd {
    list-style: none;
  }

  /**
   * Set image styles
   */

  img {
    display: block;
    max-width: 100%;
  }

  /**
   * Set SVG styles
   */

  svg {
    display: block;
  }

  /**
   * Reset link styles
   */

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  /**
   * Hide horizontal overflow
   */

  #___gatsby {
    overflow-x: hidden;
  }
`}`;
