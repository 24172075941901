import React, { FC, useState, useEffect } from "react";
import { GlobalStyle, colors, spaceRem, fontStacks } from "../global-styles";
import { useStaticQuery, graphql, Link } from "gatsby";
import { LayoutQuery } from "../../graphql-types";
import { transform, motion } from "framer-motion";
import { Helmet } from "react-helmet";

const Layout: FC<{ path: string }> = props => {
  const { path, children } = props;

  // mobile only
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const data = useStaticQuery<LayoutQuery>(graphql`
    query Layout {
      wp {
        menuItems(where: { location: PRIMARY_NAVIGATION }, first: 99999) {
          edges {
            node {
              label
              url
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    setMenuIsOpen(false);
  }, [path]);

  return (
    <>
      <Helmet htmlAttributes={{ lang: "nl" }} title="Praktijk voor Wel-Zijn" />

      <GlobalStyle />

      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <header
          style={{
            backgroundColor: colors.sherpaBlue,
            borderBottom: `2px solid ${colors.superiorWhite}`,
            boxShadow: `0 8px 30px ${colors.superiorWhite}`,
            zIndex: 2,
          }}
          css={`
            position: relative;

            @media (max-width: 600px) {
              position: sticky;
              top: 0;
            }
          `}
        >
          <div
            style={{
              maxWidth: "55rem",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div
              style={{
                position: "relative",
                height: `${2.5 * spaceRem}rem`,
                alignItems: "center",
                justifyContent: "space-between",
                fontWeight: 700,
                color: colors.superiorWhite,
                paddingLeft: `${0.5 * spaceRem}rem`,
                paddingRight: `${0.5 * spaceRem}rem`,
              }}
              css={`
                display: flex;

                @media (min-width: 600px) {
                  display: none;
                }
              `}
            >
              <div>
                <Link to="/">Praktijk voor Wel-Zijn</Link>
              </div>

              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: colors.superiorWhite,
                  fontSize: "1rem",
                  fontFamily: fontStacks.nunito,
                  fontWeight: 700,
                  border: "none",
                  backgroundColor: "transparent",
                  outline: "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setMenuIsOpen(prev => !prev);
                }}
              >
                <div>menu</div>
                <div
                  style={{
                    marginLeft: `${0.5 * spaceRem}rem`,
                    height: `${0.75 * spaceRem}rem`,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: colors.superiorWhite,
                      height: 2,
                      width: `${1 * spaceRem}rem`,
                    }}
                  />
                  <div
                    style={{
                      backgroundColor: colors.superiorWhite,
                      height: 2,
                      width: `${1 * spaceRem}rem`,
                    }}
                  />
                  <div
                    style={{
                      backgroundColor: colors.superiorWhite,
                      height: 2,
                      width: `${1 * spaceRem}rem`,
                    }}
                  />
                </div>
              </button>
            </div>

            <div
              style={{ position: "relative", height: `${4 * spaceRem}rem` }}
              css={`
                display: none;

                @media (min-width: 600px) {
                  display: block;
                }
              `}
            >
              <div
                style={{
                  position: "absolute",
                  backgroundColor: colors.superiorWhite,
                  opacity: 0.3,
                  width: `${5 * spaceRem}rem`,
                  height: `${5 * spaceRem}rem`,
                  borderRadius: "50%",
                  left: `${1 * spaceRem}rem`,
                  top: `${-2 * spaceRem}rem`,
                }}
              />

              <div
                style={{
                  position: "absolute",
                  backgroundColor: colors.superiorWhite,
                  opacity: 0.3,
                  width: `${5 * spaceRem}rem`,
                  height: `${5 * spaceRem}rem`,
                  borderRadius: "50%",
                  left: `${1 * spaceRem}rem`,
                  top: `${1 * spaceRem}rem`,
                }}
              />

              <div
                style={{
                  position: "absolute",
                  backgroundColor: colors.superiorWhite,
                  opacity: 0.3,
                  width: `${5 * spaceRem}rem`,
                  height: `${5 * spaceRem}rem`,
                  borderRadius: "50%",
                  left: `${3.5 * spaceRem}rem`,
                  top: `${-0.5 * spaceRem}rem`,
                }}
              />

              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: `${8 * spaceRem}rem`,
                  color: colors.superiorWhite,
                  fontSize: "1.6rem",
                  fontWeight: 700,
                }}
              >
                <Link to="/">Praktijk voor Wel-Zijn</Link>
              </div>
            </div>
          </div>
        </header>

        <div
          style={{
            flexGrow: 1,
            display: "flex",
            width: "100%",
            maxWidth: "55rem",
            marginLeft: "auto",
            marginRight: "auto",
            position: "relative",
          }}
        >
          <nav
            style={{
              backgroundColor: colors.anzac,
              zIndex: 3,
            }}
            css={`
              box-shadow: 0 0 50px rgba(255, 255, 255, 0.4);
              transition: all 0.25s ease;

              @media (max-width: 600px) {
                position: absolute;
                height: 100%;
                right: 0;
                overflow-y: auto;
                transform: translateX(${menuIsOpen ? 0 : "100%"});
                box-shadow: none;
              }
            `}
          >
            <ul style={{ position: "relative" }}>
              {data.wp.menuItems?.edges?.map((edge, i) => (
                <motion.li
                  key={i}
                  style={{
                    borderBottom: `1px solid ${colors.superiorWhite}`,
                    backgroundColor: transform(
                      (1 / ((data.wp.menuItems?.edges?.length as number) - 1)) *
                        i,
                      [0, 0.5, 1],
                      [
                        colors.bleachWhite,
                        colors.carrotOrange,
                        colors.roseOfSharon,
                      ]
                    ),
                  }}
                  whileHover={{
                    backgroundColor: colors.seaNymph,
                    transition: { stiffness: 1000 },
                  }}
                >
                  <Link
                    style={{
                      display: "block",
                      paddingRight: `${0.75 * spaceRem}rem`,
                      paddingLeft: `${0.25 * spaceRem}rem`,
                      borderLeft: `${0.25 * spaceRem}rem solid transparent`,
                      transition: "all 0.25s ease",
                    }}
                    css={`
                      padding-top: ${0.25 * spaceRem}rem;
                      padding-bottom: ${0.25 * spaceRem}rem;

                      @media (min-width: 600px) {
                        padding-top: ${0.5 * spaceRem}rem;
                        padding-bottom: ${0.5 * spaceRem}rem;
                      }
                    `}
                    to={new URL(edge?.node?.url || "").pathname}
                    activeStyle={{
                      borderLeft: `${0.25 * spaceRem}rem solid ${
                        colors.superiorWhite
                      }`,
                    }}
                    activeClassName="active"
                  >
                    <motion.span
                      css={`
                        display: block;
                        transition: all 0.25s ease;
                        white-space: nowrap;

                        :hover,
                        .active > & {
                          transform: translateX(${0.25 * spaceRem}rem);
                        }
                      `}
                      dangerouslySetInnerHTML={{
                        __html: edge?.node?.label as string,
                      }}
                    />
                  </Link>
                </motion.li>
              ))}
            </ul>
          </nav>

          <div
            style={{
              position: "relative",
              flexGrow: 1,
              zIndex: 2,
              display: "flex",
              flexDirection: "column",
            }}
            onClick={() => {
              setMenuIsOpen(false);
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                transition: "all 0.25s ease",
              }}
              css={`
                opacity: 0;

                @media (max-width: 600px) {
                  opacity: ${menuIsOpen ? 1 : 0};
                }
              `}
            />

            <main
              style={{ marginBottom: `${spaceRem}rem`, position: "relative" }}
            >
              {children}
            </main>

            <footer
              css={`
                position: relative;
                margin-top: auto;
                margin-left: ${0.5 * spaceRem}rem;
                padding-top: ${1 * spaceRem}rem;
                padding-bottom: ${1 * spaceRem}rem;
                padding-right: ${0.5 * spaceRem}rem;

                @media (min-width: 600px) {
                  margin-left: ${1.5 * spaceRem}rem;
                }
              `}
            >
              <div style={{ overflow: "hidden" }}>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: `${-0.25 * spaceRem}rem`,
                    marginRight: `${-0.25 * spaceRem}rem`,
                  }}
                >
                  <div
                    style={{
                      marginLeft: `${0.25 * spaceRem}rem`,
                      marginRight: `${0.25 * spaceRem}rem`,
                    }}
                  >
                    <p style={{ color: colors.orient, fontWeight: 600 }}>
                      Els de Vos - Oosterom
                    </p>

                    <p>
                      <a
                        href="tel:+31348553158"
                        style={{
                          color: colors.orient,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          width="16"
                          height="16"
                          fill={colors.orient}
                          style={{ transform: "scaleX(-1)" }}
                        >
                          <path d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z" />
                        </svg>
                        <span style={{ marginLeft: `${0.25 * spaceRem}rem` }}>
                          0348 - 55 31 58
                        </span>
                      </a>
                    </p>

                    <p>
                      <a
                        href="mailto:info@praktijkelsdevos.nl"
                        style={{
                          color: colors.orient,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          width="16"
                          height="16"
                          fill={colors.orient}
                          style={{ transform: "scaleX(-1)" }}
                        >
                          <path d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z" />
                        </svg>
                        <span style={{ marginLeft: `${0.25 * spaceRem}rem` }}>
                          info@praktijkelsdevos.nl
                        </span>
                      </a>
                    </p>
                  </div>

                  <div
                    style={{
                      marginLeft: `${0.25 * spaceRem}rem`,
                      marginRight: `${0.25 * spaceRem}rem`,
                      opacity: 0.5,
                    }}
                  >
                    {/* verleden */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        top: `${1 * spaceRem}rem`,
                      }}
                    >
                      <div
                        style={{
                          width: `${1.5 * spaceRem}rem`,
                          height: `${1.5 * spaceRem}rem`,
                          border: `1px solid ${colors.inferiorBlack}`,
                          borderRadius: "50%",
                        }}
                      />
                      <div
                        style={{
                          marginLeft: `${1 * spaceRem}rem`,
                          position: "relative",
                          top: `${-0.25 * spaceRem}rem`,
                        }}
                      >
                        verleden
                      </div>
                    </div>

                    {/* heden */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: `${0.75 * spaceRem}rem`,
                      }}
                    >
                      <div
                        style={{
                          width: `${1.5 * spaceRem}rem`,
                          height: `${1.5 * spaceRem}rem`,
                          border: `1px solid ${colors.inferiorBlack}`,
                          borderRadius: "50%",
                        }}
                      />
                      <div style={{ marginLeft: `${1.5 * spaceRem}rem` }}>
                        heden
                      </div>
                    </div>

                    {/* toekomst */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        top: `${-1 * spaceRem}rem`,
                      }}
                    >
                      <div
                        style={{
                          width: `${1.5 * spaceRem}rem`,
                          height: `${1.5 * spaceRem}rem`,
                          border: `1px solid ${colors.inferiorBlack}`,
                          borderRadius: "50%",
                        }}
                      />
                      <div
                        style={{
                          marginLeft: `${1 * spaceRem}rem`,
                          position: "relative",
                          top: `${0.25 * spaceRem}rem`,
                        }}
                      >
                        toekomst
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
